@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&family=Noto+Sans:wght@100;200;300;400;500;600;700;800;900&family=Nunito:wght@200;300;400;500;600;700;800;900&family=Open+Sans:wght@300;400;500;600;700;800&family=Raleway:wght@100;200;300;400;500;600;700;800;900&family=Rubik:wght@300;400;500;600;700;800;900&family=Sono:wght@200;300;400;500;600;700;800&display=swap");

* {
  font-family: "Rubik", sans-serif;
}

body {
  margin: 0;
  /* font-family: 'Nunito', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
      'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@media print {
  body * {
    visibility: hidden;
  }
}
/* Your CSS file */
body {
  user-select: none;
  -webkit-user-select: none; /* For Safari */
}

button {
  font-family: inherit;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* #react-select-5-listbox {
  z-index: 999999999999999 !important;
} */
@media screen and (max-width: 500px) {
  .m-hidden {
    display: none;
  }
  .exam-questions-view-panel{
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  } 
  .job-batch-card{
    clip-path: polygon(0 1%, 98% 0, 54% 46%, 25% 71%, 0 91%, 0% 50%);
  }
}

.exam-questions-view-panel::-webkit-scrollbar {
  display: none;
}
.exam-questions-view-panel {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.job-batch-card{
  clip-path: polygon(0 1%, 98% 0, 54% 46%, 25% 71%, 0 91%, 0% 50%);
}
div.password-wrapper p{
  @apply hidden;
}
.otp-form input{
  @apply py-3 text-base md:text-xl font-bold text-primary text-center border-2 border-primary rounded-lg;
  width: 50px !important;
}
@media screen and (max-width:500px) {
  .otp-form input{
     width: 45px !important;
  }
}

.DropdownMenuContent,
.DropdownMenuSubContent {
  min-width: 150px;
  background-color: white;
  border-radius: 6px;
  padding: 5px;
  box-shadow: 0px 10px 38px -10px rgba(22, 23, 24, 0.35), 0px 10px 20px -15px rgba(22, 23, 24, 0.2);
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;
}
.DropdownMenuContent[data-side='top'],
.DropdownMenuSubContent[data-side='top'] {
  animation-name: slideDownAndFade;
}
.DropdownMenuContent[data-side='right'],
.DropdownMenuSubContent[data-side='right'] {
  animation-name: slideLeftAndFade;
}
.DropdownMenuContent[data-side='bottom'],
.DropdownMenuSubContent[data-side='bottom'] {
  animation-name: slideUpAndFade;
}
.DropdownMenuContent[data-side='left'],
.DropdownMenuSubContent[data-side='left'] {
  animation-name: slideRightAndFade;
}

.DropdownMenuItem,
.DropdownMenuCheckboxItem,
.DropdownMenuRadioItem,
.DropdownMenuSubTrigger {
  font-size: 13px;
  line-height: 1;
  @apply text-gray-900;
  border-radius: 3px;
  display: flex;
  align-items: center;
  height: 20px;
  padding: 0 5px;
  position: relative;
  padding-left: 10px;
  user-select: none;
  outline: none;
}
.DropdownMenuSubTrigger[data-state='open'] {
  @apply bg-customBlue;
  color: #fff;
}
.DropdownMenuItem[data-disabled],
.DropdownMenuCheckboxItem[data-disabled],
.DropdownMenuRadioItem[data-disabled],
.DropdownMenuSubTrigger[data-disabled] {
  color: var(--mauve-8);
  pointer-events: none;
}
.DropdownMenuItem[data-highlighted],
.DropdownMenuCheckboxItem[data-highlighted],
.DropdownMenuRadioItem[data-highlighted],
.DropdownMenuSubTrigger[data-highlighted] {
  @apply bg-customBlue;
  color: var(--violet-1);
}

.DropdownMenuLabel {
  padding-left: 25px;
  font-size: 12px;
  line-height: 25px;
  color: var(--mauve-11);
}

.DropdownMenuSeparator {
  height: 1px;
  background-color: #aaa;
  margin: 5px;
}

.DropdownMenuItemIndicator {
  position: absolute;
  left: 0;
  width: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.DropdownMenuArrow {
  fill: white;
}

.IconButton {
  font-family: inherit;
  border-radius: 100%;
  height: 35px;
  width: 35px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  box-shadow: 0 2px 10px var(--black-a7);
  @apply text-primary;
}
.IconButton:hover {
  background-color: var(--violet-3);
}
.IconButton:focus {
  box-shadow: 0 0 0 2px black;
}

.RightSlot {
  margin-left: auto;
  padding-left: 20px;
  color: var(--mauve-11);
}
[data-highlighted] > .RightSlot {
  color: white;
}
[data-disabled] .RightSlot {
  color: var(--mauve-8);
}
